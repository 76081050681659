import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import Headroom from "headroom.js"
gsap.registerPlugin(ScrollToPlugin)
// import imagesLoaded from "imagesLoaded"
// import { homeFunctions } from './pages/index'
// import { initCompSlider } from './pages/single-projects'
document.getElementById('main').setAttribute('style',
`min-height: calc(100vh - ${document.getElementById('footer').offsetHeight}px)`);
// window.scrollTo(0,0)

window.addEventListener('load', () => {
  console.log('staging branch');

  // window.addEventListener('DOMContentLoaded', () => {
  const root = document.getElementsByTagName('html')[0];

  root.classList.add('is_loaded');

  if (window.location.href.indexOf('/#') > -1
    || (window.performance && window.performance.navigation.type == window.performance.navigation.TYPE_BACK_FORWARD)) {
    history.replaceState(null, '', '');
  }

  window.onpageshow = function (event) {
    if (event.persisted) {
      window.location.reload()
    }
  };

  // var imgLoad = imagesLoaded(document.querySelector('#main'));
  //   console.log('imgLoad', imgLoad);
  //   imgLoad.on( 'always', function(e) {
  //     // detect which image is broken
  //     for ( var i = 0, len = imgLoad.images.length; i < len; i++ ) {
  //       var image = imgLoad.images[i];
  //       var result = image.isLoaded ? 'loaded' : 'broken';

  //       console.log('image', image);

  //       if (image.img.parentElement.classList.contains("background-image-reference")) {
  //         image.img.parentElement.nextElementSibling.setAttribute('style', `background: url(${ image.img.currentSrc }); background-size: cover;  background-position: center;`);
  //       }
  //     }
  //   })


})

window.addEventListener('DOMContentLoaded', () => {
  // window.scrollTo(0,0)

  // if (document.body.classList.contains('home')) {
  //   homeFunctions()  
  // }
  // else if (document.body.classList.contains('single-projects')) {
  //   initCompSlider()  
  // }

  // Header
  var myElement = document.querySelector("#header");
  let headroom

  Array.from(document.querySelectorAll('.animatedStick')).forEach(item => {
    if (document.documentElement.classList.contains('header-white')) {
      headroom = new Headroom(item, {
        offset: window.innerHeight + window.innerHeight + window.innerHeight / 4,
        classes: {
          initial: "animated-stick",
          pinned: "animated-stick-up",
          unpinned: "animated-stick-down",
        },
      });
    } else {
      headroom = new Headroom(item, {
        offset: window.innerHeight / 1.5,
        classes: {
          initial: "animated-stick",
          pinned: "animated-stick-up",
          unpinned: "animated-stick-down",
        },
      });
    }

    headroom.init();
  });

  function footerForm() {
    // let form = document.querySelector('#bws-newsletter form')
    let form = document.querySelector('#bws-newsletter')
    form.addEventListener('submit', event => {
      event.preventDefault();
      event.stopImmediatePropagation();
    })

    // let footerForm = document.querySelector('#footer-newsletter .comp-newsletter')
    // let honeypot = document.querySelector('#footer-newsletter .comp-newsletter input[name=name-field]')
    // let emailInput = document.querySelector('#footer-newsletter .comp-newsletter input[type=email]')
    // console.log('footerForm', footerForm);
    // footerForm.addEventListener('submit', event => {
    //   event.preventDefault();
    //   // event.stopPropagation();s
    //   event.stopImmediatePropagation();

    //   console.log('ff', honeypot, honeypot.length, event.target.dataset, emailInput);
    // })

    // let submit = document.querySelector('#footer-newsletter form input[type=submit]')
    let submit = document.querySelector('#bws-newsletter input[type=submit]')
    submit.addEventListener('click', event => {
      event.preventDefault();
      event.stopImmediatePropagation();

      // const formData = new FormData(event.target);
      let form = new Object();
      // form.el = document.querySelector('#footer-newsletter .form-inner');
      // form.response = document.querySelector('#footer-newsletter .form-response');
      // form.success = document.querySelector('#footer-newsletter .form-response .success');
      // form.failed = document.querySelector('#footer-newsletter .form-response .failed');
      form.el = document.querySelector('#bws-newsletter .form-inner');
      form.response = document.querySelector('#bws-newsletter .form-response');
      form.success = document.querySelector('#bws-newsletter .form-response .success');
      form.failed = document.querySelector('#bws-newsletter .form-response .failed');

      // form.container =  document.querySelector('#footer-newsletter .form-container');
      // form.inner =  document.querySelector('#footer-newsletter .form-inner');
      // form.formData = formData;

      let options = {
        method: 'POST',
        mode: 'no-cors',
        body: form.formData,
      }
      let ajaxUrl = window.myajax.url + '?action=contact_form_submission'

      fetch(ajaxUrl, options)
        .then(response => response.text())
        .then(data => {
          let MCResponse = JSON.parse(data);
          console.log('MCResponse', MCResponse);

          if (MCResponse.status == "subscribed") {
            formResponseAnim(form, form.success)
          }
          // Already subscribed
          else if (MCResponse.title == "Member Exists") {
            let paragraph = form.success.querySelector('.inner p');
            paragraph.textContent = 'you\'re already Subscribed';
            formResponseAnim(form, form.success)
          }
          // Invalid error
          else if (MCResponse.title == "Invalid Resource") {
            let paragraph = form.success.querySelector('.inner p');
            paragraph.textContent = 'Invalid Email';
            formResponseAnim(form, form.failed)
          }
          // Failed
          else {
            formResponseAnim(form, form.failed)
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error)
        });
    })
  }

  // Change form to response mesasge
  function formResponseAnim(form, formType) {
    let tl = gsap.timeline();
    tl.to(form.el, {
      duration: 0.4,
      ease: "power4.inOut",
      opacity: 0,
    })
    tl.fromTo(form.el, {
      height: form.el.getBoundingClientRect().height,
    }, {
      duration: 0.4,
      ease: "power4.inOut",
      height: 0,
    })
    // tl.fromTo(form.response, {
    tl.fromTo([form.response, formType], {
      height: 0,
    }, {
      duration: 0.4,
      ease: "power4.inOut",
      height: formType.children[0].getBoundingClientRect().height,
      onComplete: () => {
        formType.height = 'auto';
      },
    }, '-=0.4')
    tl.to(formType.children[0], {
      duration: 0.4,
      ease: "power4.inOut",
      opacity: 1,
    })
  }

  footerForm()


  // Main nav accordion toggles
  let navPluses = document.querySelectorAll('#menu .desktop-plus-wrapper')
  navPluses.forEach(a => {
    a.addEventListener('click', event => {
      event.preventDefault();

      if (window.innerWidth < 1024) {
        if (!event.target.classList.contains('active')) {
          let menu = event.target.parentElement.nextElementSibling
          openMenu(event, menu)
        } else if (event.target.classList.contains('active')) {
          let menu = event.target.parentElement.nextElementSibling
          closeMenu(event, menu)
        }
      }
    })
  })

  document.querySelectorAll('.menu-item-has-children').forEach(item => {
    item.addEventListener('mouseover', function() {
      //console.log('open menu please');
      let menu = item.querySelector('.sub-menu-wrapper');
      let svg = item.querySelector('svg');

      if (menu && menu.offsetHeight == 0) {
        gsap.fromTo(menu, {
          height: 0,
        }, {
          height: menu.children[0].getBoundingClientRect().height,
          duration: 0.4,
          ease: "power1.in",
          onComplete: () => {
            gsap.set(menu, { height: 'auto' })
          },
        })
        gsap.to(svg, {
          duration: 0.4,
          ease: "power1.in",
          rotate: 45,
        })
      }
    });

    item.addEventListener('mouseleave', function(event) {
      //console.log('close menu please');
      //console.log(event.target);
      if (event.target.querySelector('a').textContent.includes("Catalogue")) {
        //console.log('close all');
        let menus = document.querySelectorAll('.sub-menu-wrapper');
        menus.forEach(item => {      
          let svg = item.parentElement.querySelector('svg');

          gsap.fromTo(item, {
            height: item.offsetHeight,
          }, {
            height: 0,
            duration: 0.4,
            ease: "power1.in",
            onComplete: () => {
              // if (event.target.parentElement.textContent.indexOf("Catalogue") > -1) {
              //   closeAllSubMenus()
              // }
            },
          })
          gsap.to(svg, {
            duration: 0.4,
            ease: "power1.in",
            rotate: 0,
          })
        })
        
      }else{
        let menu = item.querySelector('.sub-menu-wrapper');
        let svg = item.querySelector('svg');
        if (menu) {
          gsap.fromTo(menu, {
            height: menu.offsetHeight,
          }, {
            height: 0,
            duration: 0.4,
            ease: "power1.in",
            onComplete: () => {
              // if (event.target.parentElement.textContent.indexOf("Catalogue") > -1) {
              //   closeAllSubMenus()
              // }
            },
          })
          gsap.to(svg, {
            duration: 0.4,
            ease: "power1.in",
            rotate: 0,
          })
        }
      }
    })
  })

  // If changes page with hash - scroll to it
  if (window.location.hash.indexOf('#') > -1) {
    let waitForAnim = setInterval(() => {
      // Wait for animations to finish loading - then scroll - to avoid twitch
      if (document.documentElement.classList.contains('css-animations-loaded')) {
        document.documentElement.classList.add('scroll_past_viewport')
        clearInterval(waitForAnim)
        scrollToElFromHash()
      }
    }, 200)
  }

  function scrollToElFromHash() {
    let href = window.location.href;
    href = href.split('#')
    href = href[1]

    if (document.querySelector(`#${href}`) != null) {
      scrollToSection(href)
    }
  }

  function scrollToSection(href) {
    gsap.to(window, {
      onStart: () => {
        document.documentElement.classList.remove('menu_open')
        document.documentElement.classList.remove('disable_scrolling')
      },
      duration: 0.6,
      scrollTo: () => {
        if (window.innerWidth >= 601) {
          return document.querySelector(`#${href}`).getBoundingClientRect().height
        } else if (window.innerWidth <= 600) {
          return document.querySelector(`#${href}`).getBoundingClientRect().height + (window.innerHeight / 2)
        }
      },
    });
  }

  let mainmenu = document.querySelector('aside#menu')
  let anchorTo = document.querySelectorAll('.anchor-to a')
  anchorTo.forEach(link => {

    link.addEventListener('click', event => {
      if (document.body.classList.contains('home')) {
        event.preventDefault();

        let href = event.target.href;
        href = href.split('#')
        href = href[1]

        if (document.querySelector(`#${href}`) != null) {
          if (event.target.classList.contains('anchor-to-section') || event.target.parentElement.classList.contains('anchor-to-section')) {
            scrollToSection(href)
          } else {
            gsap.to(window, {
              onStart: () => {
                document.documentElement.classList.remove('menu_open')
                document.documentElement.classList.remove('disable_scrolling')
              },
              duration: 0.6,
              scrollTo: document.querySelector(`#${href}`).getBoundingClientRect().top,
            });
          }
        } else {
          window.scrollTo(window.scrollX, window.scrollY)
          console.log('else href', href);
          window.location.href = event.target.href
        }

      }
    })
  })
})

// Open curent sub menu
function openMenu(event, menu) {
  event.target.classList.add('active')
  event.target.style.pointerEvents = "none"

  gsap.fromTo(menu, {
    height: 0,
  }, {
    height: menu.children[0].getBoundingClientRect().height,
    duration: 0.4,
    ease: "power1.in",
    onComplete: () => {
      event.target.style.pointerEvents = "all"
      gsap.set(menu, { height: 'auto' })
    },
  })
  gsap.to(event.target.querySelector('svg'), {
    duration: 0.4,
    ease: "power1.in",
    rotate: 45,
  })
}

// Close curent sub menu
function closeMenu(event, menu) {
  console.log('close menu?');
  event.target.classList.remove('active')
  event.target.style.pointerEvents = "none"

  gsap.fromTo(menu, {
    height: menu.children[0].getBoundingClientRect().height,
  }, {
    height: 0,
    duration: 0.4,
    ease: "power1.in",
    onComplete: () => {
      event.target.style.pointerEvents = "all"
      if (event.target.parentElement.textContent.indexOf("Catalogue") > -1) {
        closeAllSubMenus()
      }
    },
  })
  gsap.to(event.target.querySelector('svg'), {
    duration: 0.4,
    ease: "power1.in",
    rotate: 0,
  })
}

// Close open sub menus
function closeAllSubMenus() {
  let navPluses = document.querySelectorAll('#menu .desktop-plus-wrapper')
  navPluses.forEach(plus => { plus.classList.remove('active') })
  gsap.set(['#menu .sub-menu-wrapper', '#menu .desktop-plus-wrapper svg'], {
    clearProps: "all"
  })
}